import React from "react"
import { graphql } from "gatsby"
import "twin.macro"
import Container from "../components/container"
import ApplicationForm from "../components/form/application-form"
import BackButton from "../components/back-button"
import "../css/gutenberg.css"

const FormTemplate = ({ data }) => {
  return (
    <>
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>

        <div tw="mb-6 lg:mb-10">
          <h1 tw="mb-10 max-w-5xl text-3xl font-bold lg:mb-18 lg:text-5xl">
            zusammen:wirken <br /> 2. Gleichstellungstag
          </h1>
          <div tw="grid grid-cols-12 gap-5" className="wp-content">
            <div tw="col-span-12 -mx-4 bg-sand-500 px-4 py-8 text-sm hyphens-auto sm:-mx-10 sm:px-10 lg:col-span-2 lg:mx-0 lg:bg-transparent lg:px-0 lg:pb-0 lg:pt-1.5">
              <strong>Veranstaltungsort</strong>
              <br />
              bcc Berlin Congress
              <br />
              Center
              <br />
              Alexanderstraße 11<br />
              10178 Berlin
            </div>
            <div tw="col-span-12 lg:col-span-10">
              <h2 tw="text-3xl font-bold">Anmeldung</h2>
              <p tw="text-xl">Der Anmeldezeitraum ist leider vorbei. Die Programmpunkte auf der Hauptbühne werden ganztägig über einen Livestream auf unserer Website zu verfolgen sein.</p>
              {/**<ApplicationForm></ApplicationForm>**/}
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FormTemplate

//export { Head } from "../components/head"

/*export const pageQuery = graphql`
  query HomeById {
    seo: wpPage(id: { eq: "cG9zdDoxNTMz" }) {
      ...SeoFragment
    }
  }
`*/